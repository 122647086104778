const commodityPlastic = [
  {
    name: 'HIPS',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hips_1.jpg`,
        altText: 'hips_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hips_2.jpg`,
        altText: 'hips_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hips_3.jpg`,
        altText: 'hips_3'
      },
    ]
  },
  {
    name: 'GPPS',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/gpps_1.jpg`,
        altText: 'gpps_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/gpps_2.jpg`,
        altText: 'gpps_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/gpps_3.jpg`,
        altText: 'gpps_3'
      },
    ]
  },
  {
    name: 'EPS',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/eps_1.jpg`,
        altText: 'eps_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/eps_2.jpg`,
        altText: 'eps_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/eps_3.jpg`,
        altText: 'eps_3'
      },
    ]
  },
  {
    name: 'HPP',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hpp_1.jpg`,
        altText: 'hpp_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hpp_2.jpg`,
        altText: 'hpp_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hpp_3.jpg`,
        altText: 'hpp_3'
      },
    ]
  },
  {
    name: 'BCPP',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/bcpp_1.jpg`,
        altText: 'bcpp_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/bcpp_2.jpg`,
        altText: 'bcpp_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/bcpp_3.jpg`,
        altText: 'bcpp_3'
      },
    ]
  },
  {
    name: 'HDPE',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hdpe_1.jpg`,
        altText: 'hdpe_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hdpe_2.jpg`,
        altText: 'hdpe_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/hdpe_3.jpg`,
        altText: 'hdpe_3'
      },
    ]
  },
  {
    name: 'LDPE',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ldpe_1.jpg`,
        altText: 'ldpe_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ldpe_2.jpg`,
        altText: 'ldpe_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ldpe_3.jpg`,
        altText: 'ldpe_3'
      },
    ]
  },
  {
    name: 'LLDPE',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/lldpe_1.jpg`,
        altText: 'lldpe_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/lldpe_2.jpg`,
        altText: 'lldpe_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/lldpe_3.jpg`,
        altText: 'lldpe_3'
      },
    ]
  },
  {
    name: 'PVC',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pvc_1.jpg`,
        altText: 'pvc_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pvc_2.jpg`,
        altText: 'pvc_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pvc_3.jpg`,
        altText: 'pvc_3'
      },
    ]
  },
  {
    name: 'PET',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pet_1.jpg`,
        altText: 'pet_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pet_2.jpg`,
        altText: 'pet_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pet_3.jpg`,
        altText: 'pet_3'
      },
    ]
  },
  {
    name: 'EVA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/eva_1.jpg`,
        altText: 'eva_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/eva_2.jpg`,
        altText: 'eva_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/eva_3.jpg`,
        altText: 'eva_3'
      },
    ]
 },
]
const engineeringPlastic = [
  {
    name: 'PC',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pc_1.jpg`,
        altText: 'pc_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pc_2.jpg`,
        altText: 'pc_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pc_3.jpg`,
        altText: 'pc_1'
      },
    ]
  },
  {
    name: 'POM',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pom_1.jpg`,
        altText: 'pom_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pom_2.jpg`,
        altText: 'pom_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pom_3.jpg`,
        altText: 'pom_3'
      },
    ]
  },
  {
    name: 'NYLON',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/nylon_1.jpg`,
        altText: 'nylon_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/nylon_2.jpg`,
        altText: 'nylon_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/nylon_3.jpg`,
        altText: 'nylon_3'
      },
    ]
  },
  {
    name: 'PBT',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pbt_1.jpg`,
        altText: 'pbt_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pbt_2.jpg`,
        altText: 'pbt_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pbt_3.jpg`,
        altText: 'pbt_3'
      },
    ]
  },
  {
    name: 'ABS',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/abs_1.jpg`,
        altText: 'abs_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/abs_2.jpg`,
        altText: 'abs_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/abs_3.jpg`,
        altText: 'abs_3'
      },
    ]
  },
  {
    name: 'SAN',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/san_1.jpg`,
        altText: 'san_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/san_2.jpg`,
        altText: 'san_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/san_3.jpg`,
        altText: 'san_3'
      },
    ]
  },
  {
    name: 'MABS',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mabs_1.jpg`,
        altText: 'mabs_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mabs_2.jpg`,
        altText: 'mabs_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mabs_3.jpg`,
        altText: 'mabs_3'
      },
    ]
  },
  {
    name: 'ASA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/asa_1.jpg`,
        altText: 'asa_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/asa_2.jpg`,
        altText: 'asa_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/asa_3.jpg`,
        altText: 'asa_3'
      },
    ]
  },
  {
    name: 'PETG',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/petg_1.jpg`,
        altText: 'petg_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/petg_2.jpg`,
        altText: 'petg_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/petg_3.jpg`,
        altText: 'petg_3'
      },
    ]
  },
  {
    name: 'PMMA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pmma_1.jpg`,
        altText: 'pmma_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pmma_2.jpg`,
        altText: 'pmma_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pmma_3.jpg`,
        altText: 'pmma_3'
      },
    ]
  },
  {
    name: 'TP',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/tp_1.jpg`,
        altText: 'tp_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/tp_2.jpg`,
        altText: 'tp_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/tp_3.jpg`,
        altText: 'tp_3'
      },
    ]
  },
  {
    name: 'SBC',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/sbc_1.jpg`,
        altText: 'sbc_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/sbc_2.jpg`,
        altText: 'sbc_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/sbc_3.jpg`,
        altText: 'sbc_3'
      },
    ]
  },
  {
    name: 'PAC',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pac_1.jpg`,
        altText: 'pac_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pac_2.jpg`,
        altText: 'pac_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pac_3.jpg`,
        altText: 'pac_1'
      },
    ]
  }
]

const superEngineeringPlastic = [
  {
    name: 'PPS',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pps_1.jpg`,
        altText: 'pps_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pps_2.jpg`,
        altText: 'pps_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pps_3.jpg`,
        altText: 'pps_3'
      },
    ]
  },
  {
    name: 'PPA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ppa_1.jpg`,
        altText: 'ppa_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ppa_2.jpg`,
        altText: 'ppa_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ppa_3.jpg`,
        altText: 'ppa_3'
      },
    ]
  },
  {
    name: 'PTFE',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ptfe_1.jpg`,
        altText: 'ptfe_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ptfe_2.jpg`,
        altText: 'ptfe_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ptfe_3.jpg`,
        altText: 'ptfe_3'
      },
    ]
  },
  {
    name: 'PARA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/para_1.jpg`,
        altText: 'para_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/para_2.jpg`,
        altText: 'para_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/para_3.jpg`,
        altText: 'para_3'
      },
    ]
  },
  {
    name: 'PVDF',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pvdf_1.jpg`,
        altText: 'pvdf_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pvdf_2.jpg`,
        altText: 'pvdf_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/pvdf_3.jpg`,
        altText: 'pvdf_3'
      },
    ]
  },
  {
    name: 'PPSU',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ppsu_1.jpg`,
        altText: 'ppsu_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ppsu_2.jpg`,
        altText: 'ppsu_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/ppsu_3.jpg`,
        altText: 'ppsu_3'
      },
    ]
  },
  {
    name: 'LCP',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/lcp_1.jpg`,
        altText: 'lcp_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/lcp_2.jpg`,
        altText: 'lcp_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/lcp_3.jpg`,
        altText: 'lcp_3'
      },
    ]
  },
  {
    name: 'PEEK',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/peek_1.jpg`,
        altText: 'peek_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/peek_2.jpg`,
        altText: 'peek_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/peek_3.jpg`,
        altText: 'peek_3'
      },
    ]
  },
]

const coating = [
  {
    name: 'TiO2',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/tio2_1.jpg`,
        altText: 'tio2_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/tio2_2.jpg`,
        altText: 'tio2_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/tio2_3.jpg`,
        altText: 'tio2_3'
      },
    ]
  },
  {
    name: 'ER',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/er_1.jpg`,
        altText: 'er_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/er_2.jpg`,
        altText: 'er_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/er_3.jpg`,
        altText: 'er_3'
      },
    ]
  },
  {
    name: 'MMA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mma_1.jpg`,
        altText: 'mma_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mma_2.jpg`,
        altText: 'mma_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mma_3.jpg`,
        altText: 'mma_3'
      },
    ]
  },
  {
    name: 'MAA',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/maa_1.jpg`,
        altText: 'maa_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/maa_2.jpg`,
        altText: 'maa_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/maa_3.jpg`,
        altText: 'maa_3'
      },
    ]
  },
]

const additives = [
  {
    name: 'MB',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mb_1.jpg`,
        altText: 'mb_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mb_2.jpg`,
        altText: 'mb_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/mb_3.jpg`,
        altText: 'mb_3'
      },
    ]
  },
  {
    name: 'CP',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/cp_1.jpg`,
        altText: 'cp_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/cp_2.jpg`,
        altText: 'cp_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/cp_3.jpg`,
        altText: 'cp_3'
      },
    ]
  },
  {
    name: 'P',
    pictures: [
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/p_1.jpg`,
        altText: 'p_1'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/p_2.jpg`,
        altText: 'p_2'
      },
      {
        src: `${process.env.PUBLIC_URL}/pictures/products/p_3.jpg`,
        altText: 'p_3'
      },
    ]
  },
]

const commodityList = {
  "Commodity Plastic": commodityPlastic,
  "Engineering Plastic": engineeringPlastic,
  "Super Engineering Plastic": superEngineeringPlastic,
  "Coating": coating,
  "Additives": additives
} 

export default commodityList;
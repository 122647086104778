import React from 'react';
import './home.scss'
import Navigation from "./navigation/navigation";
import OurPresence from './our presence/our-presence';
import Masthead from './masthead/masthead';
import AboutUs from './about us/about-us';
import Footer from './footer/footer';
import IndustriesApplications from './industries and applications/industries-applications';
import Products from './products/products';
import Services from './services/services';
import ContacUs from './contact us/contact-us';

export default function Home() {
  return (
    <>
      <Navigation />
      <Masthead />
      <AboutUs />
      <IndustriesApplications />
      <Products />
      <Services />
      <OurPresence />
      <ContacUs />
      <Footer />
    </>
  )
}